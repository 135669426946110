import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import HomePage from "./Home";
import BlogPost from "./BlogPost";
import BlogList from "./BlogList";
import AboutUs from "./AboutUs";
import ContactUs from "./Contact";
import Pricing from "./Pricing";
import HowItWorks from "./HowItWorks";
import "./styles/navigation.css";
import "./styles/footer.css";
import "./styles/banner.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDownloadClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    setShowComingSoon(true);
    setTimeout(() => {
      setShowComingSoon(false);
    }, 2000); // Hide the animation after 2 seconds
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="app-container">
        {showComingSoon && (
          <div className="coming-soon-animation">
            <div className="coming-soon-text">Coming Soon!</div>
          </div>
        )}
        <div className="announcement-banner">
          <p>
            🎉 ExplorEase will be released in 2025! Stay tuned for more updates!
            🎉
          </p>
        </div>
        <div className="nav-container">
          <nav className="nav-content">
            <Link to="/" className="nav-logo">
              <img
                src="/Images/ExplorEase Official Logo.png"
                alt="ExplorEase Logo"
                height="70px"
              />
            </Link>
            <div className={`nav-links ${menuOpen ? "active" : ""}`}>
              <li>
                <Link to="/" onClick={() => setMenuOpen(false)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/blogs" onClick={() => setMenuOpen(false)}>
                  Blogs
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={() => setMenuOpen(false)}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/pricing" onClick={() => setMenuOpen(false)}>
                  Pricing
                </Link>
              </li>
              {/* <li><Link to="/how-it-works" onClick={() => setMenuOpen(false)}>How It Works</Link></li> */}
              <li>
                <Link to="/contact" onClick={() => setMenuOpen(false)}>
                  Contact Us
                </Link>
              </li>
            </div>
            <a
              href="/"
              className="nav-download-now"
              onClick={handleDownloadClick}
            >
              Download Now
            </a>
            <div
              className={`hamburger-menu ${menuOpen ? "active" : ""}`}
              onClick={toggleMenu}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </nav>
        </div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blogs/:slug" element={<BlogPost />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          {/* <Route path="/how-it-works" element={<HowItWorks />} /> */}
        </Routes>
        <footer className="footer-container">
          <div className="footer-section footer-image">
            <img
              src="/Images/ExplorEase Icon Transparent.png"
              alt="ExplorEase Logo"
              className="FooterLogo"
              height="150px"
            />
          </div>
          <div className="footer-content">
            <div className="footer-section">
              <h3>Resources</h3>
              <ul>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Information</h3>
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/terms">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Questions</h3>
              <ul>
                <li>
                  <a href="mailto:info@explorease.com">info@explorease.com</a>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright-social">
            <div className="footer-social">
              <a
                href="https://www.instagram.com/exploreaseapp"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/Images/Instagram Icon.png"
                  height="25px"
                  width="auto"
                  alt="Instagram Logo"
                />
              </a>
              <a
                href="https://www.facebook.com/people/ExplorEase/61560077451768/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/Images/Facebook Icon.png"
                  height="25px"
                  alt="Facebook Logo"
                />
              </a>
            </div>
            <div>
              <p>© ExplorEase 2024. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
